import React from "react"
import { Layout } from "../components"
import { Link } from "gatsby"

import Seo from "../components/seo"

const Error = () => {
  return (
    <Layout>
      <section
        id="error"
        className="error u-margin-top-big u-margin-bottom-big"
      >
        <Seo title="error" description="Page Not Found" />
        <div className="u-header-wrap u-margin-top-big u-margin-bottom-big">
          <div className="row">
            <div className="col-1-of-1 u-center-text">
              <article className="u-center">
                <h1 className="heading-primary u-margin-top">oops.</h1>
                <h3 className="heading-ordinary heading-ordinary--dark u-margin-top">
                  The page you are looking for doesn't exist.
                </h3>
                <Link to="/" className="btn btn--primary u-margin-top">
                  home
                </Link>
              </article>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Error
